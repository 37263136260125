.form-section {
    background-color: var(--lightgray);
    padding: 6rem 0rem;
}

.forms-grid {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 0rem 12rem;
}

.downloadBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.downloadFormBtn {
    background-color: white;
    padding: 22px;
    border: none;
    text-align: center;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    color: var(--dark-blue);
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
}

.downloadFormBtn i {
    font-size: 20px;
    text-align: center;
}

.FillFormBtn {
    background-color: var(--yellow);
    padding: 22px;
    border: none;
    text-align: center;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    color: #FFF;
    font-weight: bold;
    letter-spacing: 1px;
}

.FillFormBtn i {
    font-size: 20px;
    text-align: center;
}


.submitForm {
    margin: 0 auto;
}

/* Form */
.registratinForm {
    padding: 0rem 4rem;
}

.registratinForm img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}


.formHeading {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
}

/* Mobile optimized queries */

@media screen and (max-width: 600px) {
    .form-section {
        background-color: var(--lightgray);
        padding: 1rem 1rem;
    }

    .forms-grid {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        padding: 0rem 1rem;
    }

    .downloadBtns {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .downloadFormBtn {
        padding: 12px;
        margin: 15px auto;
        text-align: center;
        border-radius: 8px;
    }


}

