.contactWrapper{
    background-color: var(--dark-blue);
    height: 100vh;
    padding: 60px;
    display: flex;
    flex-direction: row;
}

.contact-left {
    width: 40%;
}

.contact-right {
    width: 60%;
}

.contactWrapper ul li {
    color: #FFF;
    font-size: 18px;
}

.contactText {
    font-size: 42px;
    color: #FFF;
    padding-left: 20px;
}

.contactInfoArea {
    width: 80%;
    background-color:var(--light-blue);
    color: #FFF;
    padding: 20px;
    margin-left:20px;
    margin-top: 100px;
}

.contactRow {
    font-size: 20px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* letter-spacing: 1px; */
    display: inline-block;
    margin: 10px 0px;
}
 /* .contactRow i {
    flex: 2;
    font-size: 32px;
}

.contactRow span {
    flex: 9;
    float: left;
} */

.linkTag{
    text-decoration: none;
    color: white;
    
}

/* form */
.formRow {
    display: flex;
    flex-direction: row;
}

.formRow input[type=text] {
    width: 100%;
    padding: 12px 20px;
    margin: 40px 10px;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid #FFF;
    background-color: transparent;
    color: #FFF;
  }

  .formRow textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 40px 10px;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid #FFF;
    background-color: transparent;
    color: #FFF;
  }


  .contactSubmitBtn {
    margin-top: 10px;
    width: 200px;
    background-color: white;
    padding: 22px;
    border: none;
    text-align: center;
    display: block;
    cursor: pointer;
    color: var(--dark-blue);
    font-weight: bold;
    letter-spacing: 1px;
    margin-left: 15px;
  }

.contactFormInput {
    border: none;
    border-bottom: 2px solid white;
    margin: 10px;
}


@media screen and (max-width: 600px) {
    .contactWrapper{
        background-color: var(--dark-blue);
        height: 130vh;
        padding: 2px;
        display: flex;
        flex-direction: column;
    }

    .contact-left {
        width: 100%;
    }
    
    .contact-right {
        width: 100%;
    }
    
    .contactText {
        font-size: 28px;
        color: #FFF;
        align-self: center;    
    }

    .contactInfoArea {
        width: 350px;
        background-color:var(--light-blue);
        color: #FFF;
        padding: 20px;
        margin-left:20px;
        margin-top: 30px;
    }
    
    .formRow {
        display: flex;
        flex-direction: column;
        margin: 0px 20px;
    }

    .contactSubmitBtn {
        align-self: center;
        margin: 0 auto;
    }
}