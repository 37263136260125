.directorsWrapper{
  background-color: var(--lightgray);
  padding: 3rem 15rem;
  text-align: center;
  justify-content: center;
} 

.coaches-section {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  justify-content: space-around;
}

.coach {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
}

.coach img {
  border-radius: 25px;
  width: 340px; /* Adjust width as needed */
  height: 340px; /* Adjust height as needed */
  border: 4px solid var(--yellow);
  padding: 10px;
  object-fit: contain;
}

.coach .name {
  margin: 5px;
  font-weight: bold;
  color: var(--dark-blue);
}

.coach .desig {
  margin: 2px;
  color: var(--dark-blue);
} 
  /* Mobile Optimized */
@media screen and (max-width: 600px) {
  .directorsWrapper{
    background-color: var(--lightgray);
    padding: 1rem 1rem;
    text-align: center;
    justify-content: center;
  }

  .coaches-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    justify-content: space-between;
  }

  .coach img {
    align-self: center;
    margin: 25px 0px;
  }
  
}
