.TestimonialsWrapper {
    height:80vh;
    background-color: var(--yellow);
    padding: 6rem;
    display: flex;
    flex-direction: row;
}

.left-t {
    width:40%;
}

.right-t {
    width:60%;
    padding: 30px;
}


.left-t ul li {
    color: #FFF;
    font-size: 18px;
}

.testimonials {
  width: 100%;
  margin: 0px 1.5rem;
  /* border: 2px solid red; */
}

.testimonialsText{
    font-size: 42px;
    color: #FFF;
    padding-left: 20px;
}

.left-t i {
    font-size:190px;
    color: #A4CF8E;
    margin-top: 40px;
    margin-left: 15%;
}

.testimonialsSliderText {
    font-size:28px;
    color: #FFF;
    text-align: justify;
    overflow: visible;
}

/* stars */
  .personDetails {
    padding: 20px;
    display: flex;
    flex-direction: row;
  }

  .avatar {
    border-radius: 999px;
    vertical-align: middle;
  }

  .personName {
    color: #FFF;
    font-size: 22px;
    margin-left: 15px;
  }
 
  .checked {
    color: var(--dark-blue);
    font-size: 24px;
  }

  .row-display-column {
    display: flex;
    flex-direction: column;
  }

  .starsRow {
    padding-left: 10px;
    margin-top: 8px;
  }

  @media screen and (max-width: 600px) { 
    .TestimonialsWrapper {
      height:100vh;
      background-color: var(--yellow);
      padding: 1rem;
      display: flex;
      flex-direction: column;
  }
  
    .left-t {
        width:100%;
    }
    
    .right-t {
        width:100%;
    }

    .testimonialsText{
      font-size: 32px;
      color: #FFF;
    }

    .left-t i {
      font-size:80px;
      color: #A4CF8E;
    }

    .testimonialsSliderText {
      font-size:22px;
      color: #FFF;
      text-align: justify;
      justify-content: center;
  }

  }