.followUsWrapper {
    padding: 2rem 6rem;
    background-color: var(--lightgray);
}

.links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.link {
    border-radius: 999px;
    border: 3px solid var(--yellow);
    padding: 1rem;
    cursor: pointer;
}

.link:hover {
   background-color:var(--dark-blue);
   color: white;
   transition: all 0.3s;
}

.link i {
    font-size: 70px;
}


/* Mobile Optimized */
@media screen and (max-width: 600px) {
    .followUsWrapper {
        background-color: var(--lightgray);
    }
    
    .links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
    }
    
    .link {
        border-radius: 999px;
        border: 3px solid var(--yellow);
        cursor: pointer;
        margin: 20px;
        padding: 3.5rem;
    }

    .link i {
        font-size: 55px;
    }
}