 .navbarWrapper {
    display: flex;
    flex-direction: row;
    margin: 30px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 40rem;
    height:3rem;
}

.mainLogo {
    width: 300px;
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 3rem;
    color: #FFF;
}

.header-menu>li:hover {
    cursor: pointer;
    color: var(--yellow);
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}

@media screen and (max-width: 600px) { 
    .mainLogo {
        width: 250px;
    }

    .header>:nth-child(2) {
        position:fixed;
        right: 2rem;
        z-index: 99;
    }

    .header-menu {
        flex-direction: column;
        background-color: var(--appColor) ;
        padding: 2rem;
    }

    .header-menu {
        flex-direction: column;
        background-color: var(--light-blue) ;
        padding: 2rem;
        z-index: 99;
    }
} 