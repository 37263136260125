.consultContainer {
    height: 80vh;
    background-image: url('../../../src/assets/Group22.png'); /* Replace 'your-background-image.png' with your image path */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 4rem;
    animation: fadeInOut 5s ease-in-out infinite alternate;
  }
  
  .consultText {
    text-align: center;
    color: #FFF;
    font-size:42px
  }

  .consultBtn {
    background-color: white;
    padding: 22px;
    border: none;
    text-align: center;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    color: var(--dark-blue);
    font-weight: bold;
    letter-spacing: 1px;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0.8; /* Start opacity */
    }
    100% {
      opacity: 1; /* End opacity */
    }
  }

  @media screen and (max-width: 600px) {
    .consultContainer {
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0rem 0rem;
      animation: fadeInOut 5s ease-in-out infinite alternate;
    }

    .content {
      text-align: center;
      margin: 10px;
    }

    .consultText {
      color: #FFF;
      font-size: 32px;
      text-align:justify
    }
  }